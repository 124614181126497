
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { getToken } from './utils';
// import InactiveUser from './components/InactiveUser';


function ProtectedRoutes() {

  const token = getToken()
  
  return (
    <div>
        {
          token ? <Outlet /> : <Navigate to={'/login'} replace />
        }
        {/* <InactiveUser  /> */}
    </div>
  )
}

export default ProtectedRoutes;