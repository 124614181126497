import jwtDecode from "jwt-decode";
import moment from "moment/moment";

const userRole = () => {
    const user = jwtDecode(getToken())
    return user;
}
const onlyDateFormate = (date) => {
    return moment(date).format("DD/MM/YYYY")
}

const getFormattedDateTime = (date) => {
    return moment.utc(date).format("MMM DD, yyyy | hh:mm A")
};
const getFormattedDateTime1 = (date) => {
    return moment.utc(date).format("MMM DD, yyyy  hh:mm A")
};
const getDayDate = (date) => {
    return moment.utc(date).format("MMM DD yyyy")
}
const getUtcTime = (date) => {
    return moment.utc(date).format("hh:mm A")
}

const getTiem = (date) => {
    return moment(date).format("hh:mm A")
}

const getFormattedDate2 = (date) => {
    return moment(date).format("MMM DD, YYYY")
}

const getFormattedDate3 = (date) => {
    return moment(date).format("ddd, MMMM DD, yyyy")
}
const getFormattedDate4 = (date) => {
    return moment(date).format("ddd, MMM DD")
}

const getFormattedDate5 = (date) => {
    return moment(date).format("DD-MM-yyy")
}
const getDiffFromMinutes = (minutes) => {
    let difference = moment.duration(parseInt(minutes), 'minutes');
    let allHours = (difference.hours() + (24 * difference.days()));
    return (allHours > 9 ? (allHours + "h") : "0" + allHours + "h") + " " + (difference.minutes() > 9 ? difference.minutes() + "m" : "0" + difference.minutes() + "m");
}

const countTotalDuration = (value) => {
    const b = value?.split("")
    return (b[0] + b[1] + "h" + " " + b[2] + b[3] + "m")
}

const getFormatedDateOfBirth = (date) => {
    return moment(date).format("MMMM, D YYYY")
}

const getFormattedTime = (time) => {
    return moment(time, "hh:mm").format("hh:mm A");
}

const getDisplayClass = (cls) => {

    if (cls == "Y" || cls == "W")
        return "Premium Economy";
    else if (cls == "F")
        return "First";
    else if (cls == "C")
        return "Business";
    else
        return "Economy";
};

const getToken = () => {
    const token = sessionStorage.getItem("auth");
    return token;
};

const getFormattedContactNo = (mobile) => {
    return 'XXX-XXX-' + mobile.substr(6)
};

const maskCardNumber = (cardNumber) => {
    const numToString = cardNumber?.toString();
    const lastFourDigit = numToString?.slice(-4)
    return lastFourDigit?.padStart(16, "X");
};

const creditCardFormat = (value) => {
    const v = value
        .replace(/\s+/g, "")
        .replace(/[^0-9]/gi, "")
        .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
        parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join("-") : value;
}

//To convert first letter capital
const capitalizeFirstLetter = (str) => {
    const firstLetter = (str?.charAt(0))?.toUpperCase();
    const remainingLetter = str?.slice(1);
    return (firstLetter + remainingLetter);
}

// payment status on behalf of payment status id

const getCurrentPaymentStatus = (status) => {
    switch (status) {
        case 1:
            return "CCV PENDING"
        case 2:
            return "CCV-DECLINE"
        case 3:
            return "CANCELLED"
        case 4:
            return "TICKET-DONE"
        case 5:
            return "VOID"
        case 6:
            return "VOID AND FRESH"
        case 7:
            return "FUTURE-CREDIT"
        case 8:
            return "REFUND"
        case 9:
            return "CCD DECLINE"
        case 10:
            return "CCV-DONE"
        case 11:
            return "APR-Unticketed"
        default:
            return
    }
};


// web check in status common function

function checkInStatus(status) {
    switch (status) {
        case 1:
            return { label: "Completed", color: "#008F2B" }
        case 2:
            return { label: "Pending", color: "#FFA200" }
        case 3:
            return { label: "Cancelled", color: "#FF0000" }
        case 4:
            return { label: "Problem", color: "#CD5C5C" }
        case 5:
            return { label: "Refund Check-in", color: "#FF69B4" }
        case 6:
            return { label: "Not Applicable", color: "#878787" }
        default:
            return { label: "Pending", color: "#FFA200" }
    }
};

function baggageStatus(status) {
    switch (status) {
        case 1:
            return { label: "Completed", color: "#008F2B" }
        case 2:
            return { label: "Pending", color: "#FFA200" }
        case 3:
            return { label: "Cancelled", color: "#FF0000" }
        case 4:
            return { label: "Problem", color: "#00A8FF" }
        case 5:
            return { label: "Refund_Bag", color: "#FF69B4" }
        case 6:
            return { label: "Not Applicable", color: "#878787" }
        default:
            return { label: "Pending", color: "#FFA200" }
    }
};

const extractDateMonthAndYear = (datestr) => {
    const date = new Date(datestr);
    const year = date.getUTCFullYear().toString().substr(-2);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const formattedDate = `${day}${month}${year}`;
    return formattedDate
}

const dateFormater = (str) => {
    const year = parseInt(str?.substr(0, 2));
    const month = parseInt(str?.substr(2, 2)) - 1;
    const day = parseInt(str?.substr(4, 2));
    const date = new Date(2000 + year, month, day);
    return date
}

//to send selected date in react-datepicker
const setAdvanceDate = (data) => {
    let currentDate = new Date(data)
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
}


//#region space and dash
function ReplaceSpace(str) {
    if (typeof (str) !== "string") {
        return
    }
    return str.split(" ").join("-")
}

//replace space with dash
function ReplaceDash(str) {
    if (typeof (str) !== "string") {
        return
    }
    return str.split("-").join(" ")
}

//function to repplace dash with space
//#endregion




export {
    userRole,
    getFormattedDateTime,
    getFormattedDate2,
    getDiffFromMinutes,
    getFormattedTime,
    getFormattedDate3,
    getFormattedDate4,
    getDisplayClass,
    getToken,
    getFormattedDateTime1,
    getFormatedDateOfBirth,
    getFormattedContactNo,
    maskCardNumber,
    getDayDate,
    getUtcTime,
    onlyDateFormate,
    creditCardFormat,
    getFormattedDate5,
    getTiem,
    capitalizeFirstLetter,
    getCurrentPaymentStatus,
    checkInStatus,
    baggageStatus,
    extractDateMonthAndYear,
    dateFormater,
    setAdvanceDate,
    countTotalDuration,
    ReplaceSpace,
    ReplaceDash
};